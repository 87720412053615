import * as React from "react";
import HcpLayout from "../../../components/hcp/layout";
import { Seo } from "../../../components/seo";
import { TfiAngleRight } from "react-icons/tfi";
import { Link } from "gatsby";
import "./what-should-i-except.scss";
import { Col, Row } from "react-grid-system";
import AnchorLink from "react-anchor-link-smooth-scroll";
import bannerImg from "../../../images/starting-wakix/starting-wakix-hero.webp";
import bannerImgMobile from "../../../images/starting-wakix/starting-wakix-hero-mobile.webp";
import CustomVideo from "../../../components/cards/customVideo";
import videoThumbnail from "../../../images/icons/thumb-taking-to-hcp-video.webp";
import waklixForYouImg from "../../../images/what-should-i-except/wakix-for-you-cta-desktop.webp";
import waklixForYouMobileImg from "../../../images/what-should-i-except/wakix-for-you-cta-mobile.webp";
import processImg from "../../../images/what-should-i-except/personal-stories-desktop.webp";
import processMobileImg from "../../../images/what-should-i-except/personal-stories-mobile.webp";
import icon1 from "../../../images/what-should-i-except/icon-expectation-not-stimulant.svg";
import icon2 from "../../../images/icons/icon-expectation-8-weeks.svg";
import icon3 from "../../../images/what-should-i-except/icon-expectation-talk.svg";
import icon4 from "../../../images/what-should-i-except/icon-expectation-no-interactions.svg";
import icon5 from "../../../images/what-should-i-except/icon-expectation-birth-control.svg";
import icon6 from "../../../images/icons/icon-expectation.svg";
import icon7 from "../../../images/what-should-i-except/icon-expectation-not-controlled.svg";
import lindsey from "../../../images/why-wakix/lindsey-thumb.webp";
import lindseySignature from "../../../images/what-should-i-except/lindsey-name.svg";

import SocialIconBlock from "../../../components/socialIconBlock";
import { StaticImage } from "gatsby-plugin-image";

import useDeviceSize from "../../../hooks/use-device-size";
import InnerBanner from "../../../components/InnerBanner";

const WhatShouldIExcept = () => {
    const deviceSizes = useDeviceSize();
    return (
        <HcpLayout pageClass="hcp-main what-to-except">
            <InnerBanner
                topHeading="What Should I Expect?"
                bannerClass="starting"
                bannerHeroImg={bannerImg}
                bannerHeroImgMobile={bannerImgMobile}
                bannerImgAlt="Kristen, a real person with narcolepsy taking WAKIX, talking with her healthcare provider"
                heroNameImg={
                    <StaticImage
                        src="../../../images/starting-wakix/starting-wakix-hero-name.webp"
                        alt="Kristen - Living with narcolepsy Taking WAKIX since 2020"
                        width={212}
                        height={104}
                        quality={95}
                        layout="fixed"
                        placeholder="none"
                        style={{ transition: "opacity 0.5s" }}
                        imgStyle={{ opacity: 1 }}
                    />
                }
            />

            <section className="inner-first pd-btm">
                <div className="centered-content">
                    <div className="page-heading ph-btm">
                        <h1 className="font-40">
                            What Should I Expect When I Take WAKIX?
                        </h1>
                        <p>
                            When starting a medication, especially one for a
                            chronic disorder like narcolepsy, it is important to
                            talk to your healthcare provider so they can help
                            you understand what to expect with treatment.
                        </p>
                        <p className="top-spacer">
                            Here are a few things for you to discuss with your
                            healthcare provider about what to expect with WAKIX:
                        </p>
                    </div>
                </div>
            </section>

            <section className="section-card section-card--alt no-pt no-pb">
                <div className="centered-content">
                    <div className="section-card_items">
                        <ul className="icon-list icon-list--alt">
                            <li className="baseline">
                                <div className="icon smaller">
                                    <img
                                        src={icon1}
                                        alt="Not a stimulant icon"
                                    />
                                </div>
                                <div className="text">
                                    <h3>
                                        WAKIX is{" "}
                                        <span className="italic green">
                                            not
                                        </span>{" "}
                                        a stimulant
                                    </h3>
                                    <p>
                                        The way your body feels when taking
                                        WAKIX may be different from medications
                                        you have taken in the past.
                                    </p>
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>
            </section>

            <section className="section-card bg-color">
                <div className="centered-content">
                    <div className="section-card_items">
                        <ul className="icon-list icon-list--alt">
                            <li className="baseline">
                                <div className="icon smaller">
                                    <img
                                        src={icon2}
                                        alt="Calendar icon"
                                        width="67"
                                        height="67"
                                    />
                                </div>
                                <div className="text">
                                    <h3>
                                        It’s important to give WAKIX time to
                                        work
                                    </h3>
                                    <p>
                                        Everyone responds to medication
                                        differently. It's important to know that
                                        WAKIX may take some time to work and for
                                        some people, it{" "}
                                        <strong>may take up to 8 weeks</strong>{" "}
                                        to achieve a response.
                                    </p>
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>
            </section>

            <section className="section-card section-card--alt no-pt no-pb">
                <div className="centered-content">
                    <div className="section-card_items">
                        <ul className="icon-list icon-list--alt last">
                            <li className="baseline">
                                <div className="icon smaller">
                                    <img src={icon3} alt="Talk bubble icon" />
                                </div>
                                <div className="text">
                                    <h3>
                                        Discuss your progress with your
                                        healthcare provider
                                    </h3>

                                    <p>
                                        Talk about how you are feeling after
                                        starting WAKIX:
                                    </p>
                                    <ul className="blueDot-list left-indent">
                                        <li>
                                            Tell them about any{" "}
                                            <strong>improvements</strong> you
                                            notice as well as{" "}
                                            <strong>
                                                any potential side effects
                                            </strong>
                                            .
                                        </li>
                                        <li>
                                            <strong>
                                                Don't stop or change the dose on
                                                your own
                                            </strong>{" "}
                                            without talking to your healthcare
                                            provider first.
                                        </li>
                                        <li>
                                            Your doctor may be able to adjust
                                            your dose of WAKIX to find a dose
                                            that is right for you.
                                        </li>
                                    </ul>
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>
            </section>

            <section className="cta-quote bg-green">
                <div className="centered-content">
                    <div className="cta-quote__wrap">
                        <div className="cta-quote__media">
                            <img
                                src={lindsey}
                                style={{ width: "98px" }}
                                alt="Lindsey, a real person with narcolepsy taking WAKIX"
                            />
                        </div>
                        <div className="cta-quote__content">
                            <q className="quote">
                                “After I had been on my full WAKIX dose, my
                                husband pointed out I wasn't taking as many naps
                                as before.”
                            </q>
                            <div className="cta-quote__body">
                                <div className="cta-quote__sign">
                                    <img
                                        src={lindseySignature}
                                        style={{ width: "98px" }}
                                        alt="Lindsey, a real person with narcolepsy taking WAKIX"
                                    />
                                </div>
                                <div className="cta-quote__location">
                                    <p className="top-spacer show-mobile">
                                        Living with narcolepsy <br /> Taking
                                        WAKIX since 2020
                                    </p>
                                    <p className="show-desktop">
                                        Living with narcolepsy <br /> Taking
                                        WAKIX since 2020
                                    </p>
                                </div>
                            </div>
                            <div className="cta-quote__location show-desktop">
                                <p className="top-spacer">
                                    Individual responses to WAKIX may vary.
                                </p>
                            </div>
                        </div>
                    </div>

                    <div className="cta-quote__desc">
                        <q className="quote">
                            “After I had been on my full WAKIX dose, my husband
                            pointed out I wasn't taking as many naps as before.”
                        </q>
                    </div>
                    <div className="cta-quote__location show-mobile">
                        <p className="top-spacer">
                            Individual responses to WAKIX may vary.
                        </p>
                    </div>
                </div>
            </section>

            <section
                className="gray-bg video-section"
                id="kristen-drwinter-video"
            >
                <AnchorLink href="#kristen-drwinter-video" />
                <div className="centered-content">
                    <Row className="row align-items-center">
                        <Col lg={6} xl={7} className="two-column__video">
                            <div className="card-video-wrap fit">
                                <CustomVideo
                                    videoId="718434990"
                                    videoIdParam="11fe9fc292"
                                    videoThumbnail={videoThumbnail}
                                    altText={
                                        "Video thumbnail with Kristen, a real person with narcolepsy taking WAKIX, and sleep specialist Dr. Chris Winter discussing talking to your healthcare provider when starting WAKIX"
                                    }
                                    customTagText="Kristen video"
                                />
                            </div>
                        </Col>
                        <Col lg={6} xl={5}>
                            <div className="two-column__content">
                                <h2>
                                    Talking to your healthcare provider when
                                    starting WAKIX
                                </h2>
                                <p>
                                    Kristen started taking WAKIX in 2020, and
                                    Dr. Chris Winter is a sleep specialist in
                                    Virginia. Hear them share their own
                                    perspectives on what to expect when getting
                                    started on WAKIX.
                                </p>
                            </div>
                        </Col>
                    </Row>
                </div>
            </section>

            <section className="inner-first">
                <div className="centered-content">
                    <div className="page-heading">
                        <h2>What else should I know about WAKIX?</h2>
                    </div>
                </div>
            </section>

            <section className="section-card section-card--alt no-pt no-pb">
                <div className="centered-content">
                    <div className="section-card_items">
                        <ul className="icon-list icon-list--alt last">
                            <li className="baseline">
                                <div className="icon smaller">
                                    <img src={icon4} alt="Medication icon" />
                                </div>
                                <div className="text">
                                    <h3>
                                        No significant drug interactions with
                                        sodium oxybate or modafinil
                                    </h3>
                                    <p>
                                        In a clinical study where WAKIX was
                                        taken with sodium oxybate or modafinil,
                                        there were{" "}
                                        <strong>no significant effects</strong>{" "}
                                        on the levels of the medications in the
                                        body.
                                    </p>
                                    <ul className="blueDot-list left-indent">
                                        <li>
                                            Tell your healthcare provider about
                                            all the medications you take or plan
                                            to take.
                                        </li>
                                        {/* <li>For more information about the registry or to enroll, call the WAKIX Pregnancy Registry Coordination Center at <a href="tel:18773022813">1-877-302-2813</a> or visit <Link to="https://www.wakixpregnancyregistry.com">WakixPregnancyRegistry.com</Link></li> */}
                                    </ul>
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>
            </section>

            <section className="section-card bg-color">
                <div className="centered-content">
                    <div className="section-card_items">
                        <ul className="icon-list icon-list--alt last">
                            <li className="baseline">
                                <div className="icon smaller">
                                    <img
                                        src={icon5}
                                        alt="Hormonal contraceptives icon"
                                    />
                                </div>
                                <div className="text">
                                    <h3>
                                        May reduce effectiveness of hormonal
                                        contraceptives
                                    </h3>
                                    <p>
                                        WAKIX may reduce the effectiveness of{" "}
                                        <strong>
                                            {" "}
                                            hormonal contraceptives (birth
                                            control).
                                        </strong>
                                    </p>
                                    <ul className="blueDot-list left-indent">
                                        <li>
                                            Women who can become pregnant should
                                            use an alternative non-hormonal type
                                            of birth control while taking WAKIX
                                            and for at least 21 days after
                                            discontinuation of treatment.
                                        </li>
                                    </ul>
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>
            </section>

            <section className="section-card section-card--alt no-pt no-pb">
                <div className="centered-content">
                    <div className="section-card_items">
                        <ul className="icon-list icon-list--alt last">
                            <li className="baseline">
                                <div className="icon smaller">
                                    <img
                                        src={icon6}
                                        alt="Pregnancy registry icon"
                                    />
                                </div>
                                <div className="text">
                                    <h3>Voluntary pregnancy registry</h3>
                                    <p>
                                        You are encouraged to enroll in the
                                        WAKIX Pregnancy Registry if you are
                                        pregnant and taking WAKIX or have taken
                                        WAKIX at any time during your pregnancy.
                                    </p>
                                    <ul className="blueDot-list left-indent">
                                        <li>
                                            This voluntary registry helps
                                            researchers learn more about the
                                            safety of WAKIX when used during
                                            pregnancy.
                                        </li>
                                        <li>
                                            For more information about the
                                            registry or to enroll, call{" "}
                                            <nobr>1-800-833-7460</nobr>.
                                        </li>
                                        {/* <li>For more information about the registry or to enroll, call the WAKIX Pregnancy Registry Coordination Center at <a href="tel:18773022813">1-877-302-2813</a> or visit <Link to="https://www.wakixpregnancyregistry.com">WakixPregnancyRegistry.com</Link></li> */}
                                    </ul>
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>
            </section>

            <section className="section-card bg-color last">
                <div className="centered-content">
                    <div className="section-card_items">
                        <ul className="icon-list icon-list--alt last">
                            <li className="baseline">
                                <div className="icon smaller">
                                    <img
                                        src={icon7}
                                        alt="Not a controlled substance pill bottle icon"
                                    />
                                </div>
                                <div className="text">
                                    <h3>
                                        Not a{" "}
                                        <span className="tooltip green">
                                            controlled substance
                                            <span className="tooltip-text">
                                                A drug or chemical that is
                                                regulated by the government
                                                based on its potential for abuse
                                                and dependence.
                                            </span>
                                        </span>
                                    </h3>
                                    <p>
                                        WAKIX is the first and only{" "}
                                        <nobr>FDA-approved</nobr> treatment for
                                        people with narcolepsy that is{" "}
                                        <strong>
                                            <i className="italic">not</i> a
                                            controlled substance
                                        </strong>
                                        .
                                    </p>
                                    <ul className="blueDot-list left-indent">
                                        <li>
                                            In a clinical study, WAKIX did not
                                            show potential for abuse, similar to
                                            placebo (sugar pill).
                                        </li>
                                    </ul>
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>
            </section>

            <section className="two-col two-col--alt no-pb no-pt">
                <div className="col bg-img col--alt">
                    <div className="two-col__inner bg-cover cta-img-hgt">
                        {deviceSizes?.smUp ? (
                            <img
                                src={waklixForYouImg}
                                className="two-col"
                                alt="WAKIX for You Patient Case Manager with headset"
                            />
                        ) : (
                            <img
                                src={waklixForYouMobileImg}
                                className="two-col"
                                alt="WAKIX for You Patient Case Manager with headset"
                            />
                        )}
                        <div className="two-col__content">
                            <div className="two-col__title">
                                <h3>
                                    The{" "}
                                    <span className="italic blue">
                                        WAKIX for You
                                    </span>{" "}
                                    patient support program is here to help
                                </h3>
                            </div>
                            {/* <div className="two-col__title text-white has-textShadow">
                                <h3><i className="italic">WAKIX for You</i> <br className="show-desktop" /> Patient Support <br className="show-desktop" /> Program</h3>
                                <p>can help you get started and stay on track with WAKIX.</p>
                            </div> */}
                            <div className="btn-wrap top-spacer">
                                <Link
                                    to="/wakix-for-you-support-program"
                                    className="btn btn-primary btn-primary--md"
                                >
                                    View available support
                                    <TfiAngleRight />
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col bg-img">
                    <div className="two-col__inner bg-cover">
                        <img
                            src={processImg}
                            className="two-col__desktopImg"
                            alt="Chris, a real person with narcolepsy with
cataplexy taking WAKIX, sitting with friends
outdoors"
                        />
                        <img
                            src={processMobileImg}
                            className="two-col__mobileImg"
                            alt="Chris, a real person with narcolepsy with
cataplexy taking WAKIX, sitting with friends
outdoors"
                        />
                        <div className="two-col__content cout-left-spacer">
                            <div className="two-col__title text-white has-textShadow">
                                <h3>
                                    Hear from real <br /> people taking <br />{" "}
                                    WAKIX
                                </h3>
                            </div>
                            <div className="btn-wrap top-spacer">
                                <Link
                                    to="/wakix-personal-stories"
                                    className="btn btn-primary btn-primary--md"
                                >
                                    See their stories
                                    <TfiAngleRight />
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <SocialIconBlock />
        </HcpLayout>
    );
};

export default WhatShouldIExcept;

export const Head = () => (
    <Seo
        title="What to Expect With WAKIX® (pitolisant)"
        description="Learn about what to expect when you start taking WAKIX"
        keywords="starting WAKIX, starting pitolisant, WAKIX what to expect, pitolisant what to expect"
    />
);
